import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';

export const firebaseConfig = {
	apiKey: "AIzaSyAE4DGa9in49A2Z9QSN1qP3kDQ3__IoN3c",
	authDomain: "devlift-website.firebaseapp.com",
	projectId: "devlift-website",
	storageBucket: "devlift-website.appspot.com",
	messagingSenderId: "354041155246",
	appId: "1:354041155246:web:6e02df76b52c457fec273f",
	measurementId: "G-NGPZSL12TZ"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
export default firebaseApp;