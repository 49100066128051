import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { FirebaseAppProvider } from "reactfire";
import { firebaseConfig } from "./firebaseConfig";
import { initializeApp } from "@firebase/app";
import { hydrate, render } from "react-dom";

export const app = initializeApp(firebaseConfig);
const rootElment = document.getElementById('root');

if (rootElment.hasChildNodes()) {
  hydrate(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </FirebaseAppProvider>,
    rootElment
  );
} else {
  render(
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <HelmetProvider>
        <Provider store={store}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </Provider>
      </HelmetProvider>
    </FirebaseAppProvider>,
    rootElment
  );
}

reportWebVitals();
