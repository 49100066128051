import React from 'react';
import {dsnCN} from "../../../hooks/helper";

function MenuContent({className}) {
    const socialData = [
        {link: "https://www.facebook.com/devlift", name: "Facebook"},
        {link: "https://www.instagram.com/devlift.media/?hl=en", name: "Instagram"},
        {link: "https://www.linkedin.com/company/devliftmedia/", name: "Linkedin"},
    ];
    return (
        <div className={dsnCN('container-content  d-flex flex-column justify-content-center', className)}>
            <div className="nav__info">
                {/* <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Studio</h5>
                    <p>
                    240 Waterloo St Suite 101, <br/> London, ON, N6B 2N4
                    </p>

                </div> */}
                <div className="nav-content">
                    <h5 className="sm-title-block mb-10">Contact</h5>
                    <p className="links over-hidden mb-1">
                        {/* <a className="link-hover" href="tel:+1-833-DEVLIFT" data-hover-text="+1-833-DEVLIFT">
                           Cell: +1-833-DEVLIFT - Ext:  700
                        </a> */}
                         <strong>T: </strong><a className="link-hover" href="tel:+1-833-DEVLIFT"  rel="noreferrer">+1-833-DEVLIFT - EXT: 700</a>
                        
                        {/* <br/>
                        <a className="link-hover" href="tel:+1 (833) 338-5438" data-hover-text="+1 (833) 338-5438">
                           Mobile: +1 (833) 338-5438
                        </a> */}
                    </p>
                    <p className="links over-hidden">
                    <strong>E: </strong> <a className="link-hover" href="mailto:info@devlift.com"
                           data-hover-text="info@devlift.com" rel="noreferrer">info@devlift.com</a>
                    </p>
                </div>
            </div>
            <div className="nav-social nav-content mt-30">
                <div className="nav-social-inner p-relative">
                    <h5 className="sm-title-block mb-10">Follow us</h5>
                    <ul>
                        {socialData.map((item, index) =>
                            <li key={index}>
                                <a href={item.link} target="_blank" rel="nofollow noopener noreferrer">{item.name}</a>
                            </li>)}
                    </ul>
                </div>
            </div>
        </div>
    );
}

export default MenuContent;