import React, { useLayoutEffect, useEffect } from "react";
// Importing the Bootstrap CSS
import 'swiper/css/pagination';

import 'bootstrap/dist/css/bootstrap-grid.css';

import './assets/sass/style.scss';

import Router from "./router/Router";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import dsnEffect, { fade, moveSection, textAnimation } from "./animation/DsnEffect";
import Layout from "./layout/Layout";
import { FirebaseAppProvider, AnalyticsProvider, useFirebaseApp, FirestoreProvider } from 'reactfire';
import { getAnalytics } from 'firebase/analytics';
import TagManager from 'react-gtm-module';
import { getFirestore } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyAE4DGa9in49A2Z9QSN1qP3kDQ3__IoN3c",
    authDomain: "devlift-website.firebaseapp.com",
    projectId: "devlift-website",
    storageBucket: "devlift-website.appspot.com",
    messagingSenderId: "354041155246",
    appId: "1:354041155246:web:6e02df76b52c457fec273f",
    measurementId: "G-NGPZSL12TZ"
};



function App() {
    const app = useFirebaseApp();
    const analytics = getAnalytics(app);
    const firestoreInstance = getFirestore(useFirebaseApp());
    useLayoutEffect(() => {

        gsap.registerPlugin(ScrollTrigger);
        dsnEffect.registerEffect(moveSection, fade, textAnimation)


        gsap.config({
            nullTargetWarn: false
        });
        gsap.defaults({
            ease: "none",
            duration: 1,
            overwrite: "auto",
        });
    }, []);

    useEffect(() => {
        TagManager.initialize({ gtmId: 'GTM-NVS8MWS' });
    }, []);

    return (
        <FirebaseAppProvider firebaseConfig={firebaseConfig}>
            <FirestoreProvider sdk={firestoreInstance}>
                <AnalyticsProvider sdk={analytics}>
                    <Layout className="v-dark background-main" tag="main">
                        <Router />
                    </Layout>
                </AnalyticsProvider>
            </FirestoreProvider>
        </FirebaseAppProvider>



    );
}

export default App;
